<template>
  <div>
    <div class="detail">
      <div class="detail-head">
        <div class="text">
          <h3>{{ detail.taskName }}</h3>
          <p>
            {{ $t("demand.table.collection_time") }}：
            {{ dateFormat(detail.startTime) }} ~
            {{ dateFormat(detail.endTime) }}
          </p>
        </div>
        <a-button type="primary" class="back" @click="back">
          {{ $t("go_back") }}
          <!-- 返回 -->
        </a-button>
      </div>
      <div class="detail-body">
        <p class="text">{{ $t("demand.call_for_requests") }}：</p>
        <div class="content">
          <div class="vhtml" v-html="detail.intro"></div>
        </div>
      </div>
    </div>
    <Record :dataSource="dataSource" :budgetAmount="budgetAmount" @add="toAdd" v-if="recordShow" />
    <Form
      :dataSource="dataSource"
      :editData="editData"
      :budgetAmount="budgetAmount"
      @back="back"
      v-if="formShow"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { collectDetail } from "@/api/demand";
import { dateFormat } from "@/utils/tools";
import Record from "./record.vue";
import Form from "./form.vue";
export default {
  components: {
    Record,
    Form,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
    budgetAmount: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const stateData = reactive({
      detail: {},
      recordShow: true,
      formShow: false,
      editData: null,
    });

    collectDetail(props.dataSource.taskId).then((res) => {
      stateData.detail = res.data;
    });

    const back = () => {
      if (stateData.formShow) {
        stateData.recordShow = true;
        stateData.formShow = false;
      } else {
        emit("back");
      }
    };

    const toAdd = (data) => {
      if (data) {
        stateData.editData = JSON.parse(JSON.stringify(data));
      } else {
        stateData.editData = null;
      }
      stateData.recordShow = false;
      stateData.formShow = true;
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      back,
      toAdd,
    };
  },
};
</script>

<style lang="less" scoped>
.detail {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  &-head {
    border-bottom: 1px solid #eee;
    padding-bottom: 24px;
    margin-bottom: 24px;
    .mixinFlex(space-between);
    .text {
      width: calc(100% - 100px);
      h3 {
        color: #333;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        margin: 0;
        word-break: break-all;
      }
      p {
        color: #333;
        font-size: 14px;
        line-height: 26px;
        margin: 4px 0 0;
      }
    }
  }
  &-body {
    .text {
      color: #333;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
      margin-bottom: 16px;
    }
    .content {
      max-height: 340px;
      overflow-y: auto;
      margin-right: -10px;
      padding-right: 10px;
    }
  }
}
.back {
  border-radius: 4px;
}
</style>
